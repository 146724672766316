<template>
  <div class="dashboard">
    <div class="dashboard__body">
      <h2 class="mb-2" v-if="validateTitle().actionPlanCL.length">Clima laboral</h2>
      <div class="boxes">
        <v-row dense>
          <v-col cols="6" md="4">
            <router-link :to="{ name: 'ListActionPlanEvidences' }">
              <div class="text">
                <v-icon>mdi-format-list-checkbox</v-icon>
                <div class="text__description">Plan de acción</div>
              </div>
            </router-link>
          </v-col>
          <v-col cols="6" md="4">
            <router-link :to="{ name: 'ReportActionPlans' }">
              <div class="text">
                <v-icon>mdi-table-clock</v-icon>
                <div class="text__description">Reporte</div>
              </div>
            </router-link>
          </v-col>
          <v-col cols="6" md="4">
            <validate-activity
              v-slot:default="{ isDisabled }"
              module="climaLaboral"
              page="dashboardPlanAccion"
              activity="verDashboard"
            >
              <router-link
                :to="{ name: 'DashboardActionPlan' }"
                v-if="!isDisabled"
              >
                <div class="text">
                  <v-icon>mdi-chart-box</v-icon>
                  <div class="text__description">Dashboard</div>
                </div>
              </router-link>
            </validate-activity>
          </v-col>
        </v-row>
      </div>
      <v-divider v-if="validateTitle().actionPlanSC.length"></v-divider>
      <h2 class="mt-2 mb-2" v-if="validateTitle().actionPlanSC.length">Satisfacción al cliente</h2>
      <div class="boxes">
        <v-row dense>
          <v-col cols="6" md="4">
            <router-link :to="{ name: 'ListActionPlanEvidencesSatifaction' }">
              <div class="text">
                <v-icon>mdi-format-list-checkbox</v-icon>
                <div class="text__description">Plan de acción</div>
              </div>
            </router-link>
          </v-col>
          <v-col cols="6" md="4">
            <router-link :to="{ name: 'ReportActionPlansSatisfaction' }">
              <div class="text">
                <v-icon>mdi-table-clock</v-icon>
                <div class="text__description">Reporte</div>
              </div>
            </router-link>
          </v-col>
          <v-col cols="6" md="4">
            <validate-activity
              v-slot:default="{ isDisabled }"
              module="climaLaboral"
              page="dashboardPlanAccion"
              activity="verDashboard"
            >
              <router-link
                :to="{ name: 'DashboardActionPlanSatisfaction' }"
                v-if="!isDisabled"
              >
                <div class="text">
                  <v-icon>mdi-chart-box</v-icon>
                  <div class="text__description">Dashboard</div>
                </div>
              </router-link>
            </validate-activity>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import ValidateActivity from "@/components/global/ValidateActivity.vue";
export default {
  name: "Dashboard", // [SOLO] Dashboard a DashboardComponent
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    checkModule({ moduleName = "", pageName = "" }) {
      let found = false;
      const activities = this.$store.state.auth.activities || [];
      if (Array.isArray(activities)) {
        found = activities.some((activity) => {
          return (
            activity.module_name == moduleName && activity.page_name == pageName
          );
        });
      }
      return found;
    },

    validateTitle() {
      const activities = this.$store.state.auth.activities || [];
      const actionPlanClimaLaboral = activities.filter(
        (activity) => activity.module_name === "climaLaboral"
      );
      const actionPlanSatisfactionClient = activities.filter(
        (activity) => activity.module_name === "satisfaccionCliente"
      );

      return {
        actionPlanCL: actionPlanClimaLaboral,
        actionPlanSC: actionPlanSatisfactionClient,
      };
    },
  },
  components: {
    ValidateActivity,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/dashboardHome.scss";
</style>
